import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import {
  type UserInfoUpdateAction
} from './userInfoReducer'

function AdditionalInfoEdit ({
  additionalInfo,
  dispatch
}: {
  additionalInfo?: string
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5 }}>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            추가 정보
          </Typography>
        </Grid>
        {/* additionalInfo */}
        <Grid item xs={12}>
          <Box sx={{ p: 0.5, m: 0.5 }}>
            <TextField
              sx={{ display: 'flex' }}
              label='하고 싶은 말'
              value={additionalInfo}
              multiline
              onChange={
                (evt) => {
                  dispatch({
                    type: 'plaintext',
                    key: 'additionalInfo',
                    data: evt.target.value
                  })
                }
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AdditionalInfoEdit
