import { Box, Container } from '@mui/material'
import { type UserInfo } from '../api'
import UserInfoPreview from './user-info/display/UserInfoPreview'

function SearchResult ({ userInfoList }: {
  userInfoList: UserInfo[]
}): JSX.Element {
  return (
      <Container sx={{ overflow: 'auto' }}>
        { userInfoList.map(userInfo =>
          <Box key={userInfo.userId}>
            <UserInfoPreview userInfo={userInfo}>
            </UserInfoPreview>
            <br/>
          </Box>

        ) }
      </Container>
  )
}

export default SearchResult
