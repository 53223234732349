import {
  Grid,
  Paper,
  Table,
  TableBody, TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { type Contact } from '../../../api'

function ContactDisplay ({ contactList }: {
  contactList?: Contact[]
}): JSX.Element {
  if (contactList === undefined) {
    return <div/>
  }

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
      {/* contactList */}
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            연락처
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {contactList.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell component="th" scope="row">
                      {contact.contactType}
                    </TableCell>
                    <TableCell align="center">
                      {contact.content}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContactDisplay
