import { AddAPhoto } from '@mui/icons-material'
import {
  Badge, Box,
  Button,
  Menu, MenuItem,
  Typography, styled
} from '@mui/material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UserInfoApi } from '../../../api'
import apiConfig from '../../../api/config'
import ProfileImage from '../display/ProfileImage'
import ProfileImagePreventDialog from './ProfileImagePreventDialog copy'
import ProfileImagePreviewDialog from './ProfileImagePreviewDialog'

// Hidden Input
const FileInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

async function handleProfileImageUpload (
  userId: string,
  imageFile: File | null
): Promise<string | null> {
  const userInfoApi = new UserInfoApi(apiConfig)
  if (imageFile !== null) {
    const response = await userInfoApi.updateUserProfileImage(userId, imageFile)
    return response.data
  } else {
    const response = await userInfoApi.deleteUserProfileImage(userId)
    return response.data
  }
}

function ProfileImageUpload ({
  userInfoUpdated,
  profileImageUrl,
  setProfileImageUrl,
  name,
  englishName,
  generation
}: {
  userInfoUpdated: boolean
  profileImageUrl: string | null
  setProfileImageUrl: React.Dispatch<string | null>
  name: string
  englishName: string
  generation: string
}): JSX.Element {
  const { userId } = useParams()
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [preventDialogOpen, setPreventDialogOpen] = useState(false)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const { refetch } = useQuery({
    queryKey: [userId, imageFile],
    queryFn: async (): Promise<string | null> => {
      if (userId !== undefined) {
        return await handleProfileImageUpload(
          userId,
          imageFile
        )
      }
      return null
    },
    onSuccess: (data) => { setProfileImageUrl(data) },
    enabled: false,
    retry: 1
  })

  const onUploadConfirm = (): void => {
    setDialogOpen(false)
    refetch().catch(() => {})
  }

  const onUploadCancel = (): void => {
    setDialogOpen(false)
  }

  const buttonSize = 50
  const dialogOpenButton = (
    <Button
      sx={{ width: buttonSize, height: buttonSize }}
      component="label"
      variant="text"
      onClick={(evt) => {
        if (userInfoUpdated) {
          setMenuAnchorEl(evt.currentTarget)
        } else {
          setPreventDialogOpen(true)
        }
      }}
    >
      <AddAPhoto
        sx={{ color: '#FFA500', width: buttonSize, height: buttonSize }}
      />
    </Button>
  )

  return (
    <Box>
      <ProfileImagePreviewDialog
        open={dialogOpen}
        imageFile={imageFile}
        onConfirm={onUploadConfirm}
        onCancel={onUploadCancel}
      />
      <ProfileImagePreventDialog
        open={preventDialogOpen}
        onClose={() => { setPreventDialogOpen(false) }}
      />
      {/* Profile Image */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={2}
      >
        <Menu
          open={menuAnchorEl !== null}
          anchorEl={menuAnchorEl}
          onClose={() => { setMenuAnchorEl(null) }}>
          <Box component="label">
            <MenuItem>
              이미지 업로드
              <FileInput
                type="file"
                accept="image/*"
                onChange={
                  (evt) => {
                    if (evt.target.files !== null) {
                      setImageFile(evt.target.files[0])
                      setDialogOpen(true)
                    }
                    setMenuAnchorEl(null)
                  }
                }
              />
            </MenuItem>
          </Box>
          <MenuItem
            onClick={() => {
              setMenuAnchorEl(null)
              setImageFile(null)
              setDialogOpen(true)
            }}
          >이미지 삭제</MenuItem>
        </Menu>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={dialogOpenButton}
        >
          <ProfileImage
            profileImageUrl={profileImageUrl}
            sx={{ width: 175, height: 175 }}
          />
        </Badge>
      </Box>
      {/* name + englishName */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={1}
      >
        <Typography variant='h5'>{
          `${
            name !== '' ? name : '???'
          } (${
            englishName !== '' ? englishName : '?'
          })`
        }
        </Typography>
      </Box>
      {/* generation */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={1}
      >
        <Typography variant='h6'>
          {`공우 ${generation !== '' ? generation : '?'}기`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ProfileImageUpload
