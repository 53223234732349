import {
  Box,
  TextField
} from '@mui/material'
import React from 'react'
import {
  type UserInfoUpdateAction
} from './userInfoReducer'

function KeywordListUserInfoEdit ({ name, value, required, dispatch }: {
  name: string
  value: string
  required: boolean
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  return (
    <Box sx={{ p: 0.5, m: 0.5 }}>
      <TextField
        sx={{ display: 'flex' }}
        label={name}
        value={value}
        required={required}
        error={required && value === ''}
        helperText="공백 없이 ','로 구분해 입력하세요. 예) AI,ML,운동"
        onChange={
          (evt) => {
            dispatch({
              type: 'keywordList',
              keywordListStr: evt.target.value
            })
          }
        }
      />
    </Box>
  )
}

export default KeywordListUserInfoEdit
