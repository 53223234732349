// CustomAppbar.tsx
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import { Link } from 'react-router-dom'
import logoAppbar from '../figures/logo32.svg'
import CustomMenu from './CustomMenu'

function CustomAppbar ({ handleDrawerOpen }: {
  handleDrawerOpen: (event: React.MouseEvent<HTMLElement>) => void
}): JSX.Element {
  const [
    menuAnchorEl,
    setMenuAnchorEl
  ] = React.useState<HTMLElement | null>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setMenuAnchorEl(null)
  }

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#FFB733' }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ marginRight: 2 }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Box
          component={Link}
          to='/home'
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '15%', flex: 1 }}
        >
          <Box
            component="img"
            src={logoAppbar}
            alt="logo"
            className="logoAppbar"
            sx={{ width: '15%', maxWidth: 50, flex: 1 }}
          />
        </Box>
        <Box>
          <IconButton
            size="large"
            aria-label="account of the current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <CustomMenu
            onClose={handleMenuClose}
            anchorEl={menuAnchorEl}
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppbar
