import { createBrowserRouter } from 'react-router-dom'
import LoginRequired from './auth/LoginRequired'
import LogoutRequired from './auth/LogoutRequired'
import ChangePasswordScreen from './screen/ChangePasswordScreen'
import HomeScreen from './screen/HomeScreen'
import LoginScreen from './screen/LoginScreen'
import NotFoundScreen from './screen/NotFoundScreen'
import RegisterScreen from './screen/RegisterScreen'
import ScreenWrapper from './screen/ScreenWrapper'
import SearchScreen from './screen/SearchScreen'
import UserInfoEditScreen from './screen/UserInfoEditScreen'
import UserInfoViewScreen from './screen/UserInfoViewScreen'

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <LogoutRequired/>,
    children: [
      {
        path: '/auth/login',
        element: <LoginScreen/>
      },
      {
        path: '/auth/register',
        element: <RegisterScreen/>
      }
    ]
  },
  {
    path: '/',
    element: <LoginRequired/>,
    children: [
      {
        path: '/',
        element: <ScreenWrapper/>,
        children: [
          {
            path: '/home',
            element: <HomeScreen/>
          },
          {
            path: '/search',
            element: <SearchScreen/>
          },
          {
            path: '/change-password',
            element: <ChangePasswordScreen/>
          },
          {
            path: '/profile/:userId',
            element: <UserInfoViewScreen/>
          },
          {
            path: '/profile/:userId/edit',
            element: <UserInfoEditScreen/>
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <NotFoundScreen/>
  }
])

export default router
