import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'

function SearchBox ({ initialQuery, onSearch, focusOnRender }: {
  initialQuery?: string
  onSearch?: (query: string) => void
  focusOnRender?: boolean
}): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery ?? '')

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchQuery(event.target.value)
  }

  const handleSearch = (): void => {
    if (onSearch !== undefined) {
      onSearch(searchQuery)
    }
  }

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <Box>
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyPress = {handleKeyPress}
        inputRef={input => focusOnRender === true && input?.focus()}
        variant="outlined"
        sx={{ width: '100%' }}
        helperText={'이름, 기수, 전공, 키워드, 거주지, 경력, 학력으로 검색'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}

export default SearchBox
