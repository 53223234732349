import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useReducer } from 'react'
import { type ExperienceUpdate } from '../../../api'
import TextUserInfoEdit from './TextUserInfoEdit'

export interface ExperienceUpdateAction {
  type: 'plaintext'
  key: 'company' | 'content' | 'startYear' | 'endYear'
  data: string
}

function experienceUpdateReducer (
  experienceUpdate: ExperienceUpdate,
  action: ExperienceUpdateAction
): ExperienceUpdate {
  if (action.key === 'startYear' || action.key === 'endYear') {
    const n = action.data !== '' && Number(action.data)
    return {
      ...experienceUpdate,
      [action.key]: Number.isInteger(n) ? n : undefined
    }
  } else {
    return {
      ...experienceUpdate,
      [action.key]: action.data
    }
  }
}

function ExperienceAppendDialog ({
  open, onConfirm, onCancel
}: {
  open: boolean
  onConfirm: (item: ExperienceUpdate) => void
  onCancel: () => void
}): JSX.Element {
  const [experienceUpdate, dispatch] = useReducer(
    experienceUpdateReducer,
    {
      company: '',
      content: ''
    }
  )

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>경력 추가</DialogTitle>
      <DialogContent>
        <TextUserInfoEdit
          keyName='company'
          name='기관'
          value={experienceUpdate.company}
          required={true}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='content'
          name='내용'
          value={experienceUpdate.content}
          required={true}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='startYear'
          name='시작 연도'
          value={String(experienceUpdate.startYear ?? '')}
          required={false}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='endYear'
          name='끝 연도'
          value={String(experienceUpdate.endYear ?? '')}
          required={false}
          dispatch={dispatch}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          sx={{ color: '#FFA500' }}
        >
          취소
        </Button>
        <Button
          onClick={() => { onConfirm(experienceUpdate) }}
          sx={{ color: '#FFA500' }}
          disabled={
            experienceUpdate.content === '' ||
            experienceUpdate.company === ''
          }
          autoFocus
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExperienceAppendDialog
