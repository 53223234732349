import { Box, Button, Container, styled } from '@mui/material'
import { useEffect, useReducer, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { UserInfoApi, type UserInfo, type UserInfoUpdate } from '../api'
import apiConfig from '../api/config'
import UserInfoEdit from '../components/user-info/edit/UserInfoEdit'
import { userInfoReducer } from '../components/user-info/edit/userInfoReducer'
import { CustomAlert } from '../styles/utils'

const fetchUserInfo = async (userId?: string): Promise<UserInfo | null> => {
  if (userId === undefined) {
    return null
  }
  const userInfoApi = new UserInfoApi(apiConfig)
  try {
    const response = await userInfoApi.getUserInfo(userId)
    return response.data
  } catch {
    return null
  }
}

interface UserIdContext {
  userId: string
}

const updateUserInfo = async (
  userId: string | undefined,
  userInfoUpdate: UserInfoUpdate
): Promise<void> => {
  if (userId === undefined) {
    throw new Error('Not Authorized')
  }

  const userInfoApi = new UserInfoApi(apiConfig)
  await userInfoApi.updateUserInfo(userId, userInfoUpdate)
}

export const EditButton = styled(Button)({
  variant: 'contained',
  backgroundColor: '#FFA500',
  color: '#ffffff',
  marginTop: '15px',
  marginBottom: '15px',
  width: '50%',
  height: '50px',
  '&:hover': {
    backgroundColor: '#808080'
  }
})

function UserInfoEditScreen (): JSX.Element {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { userId: loggedInUserId } = useOutletContext<UserIdContext>()
  const [fetchLoading, setFetchLoading] = useState(false)
  const [userInfoUpdate, dispatch] = useReducer(userInfoReducer, {
    name: '',
    englishName: '',
    generation: '',
    major: ''
  })
  const [userInfoUpdated, setUserInfoUpdated] = useState(false)
  const [profileImageUrl, setProfileImageUrl] =
    useState<string | null>(null)

  const valid =
    userInfoUpdate.name !== '' &&
    userInfoUpdate.englishName !== '' &&
    userInfoUpdate.generation !== '' &&
    userInfoUpdate.major !== '' &&
    userInfoUpdate.contactUpdateList !== undefined &&
    userInfoUpdate.contactUpdateList.length >= 1

  useEffect(() => {
    if (userId !== loggedInUserId) {
      navigate(`/profile/${userId}`)
    }
    setFetchLoading(true)
    fetchUserInfo(userId)
      .then((userInfo) => {
        setFetchLoading(false)
        if (userInfo !== null) {
          dispatch({ type: 'init', userInfo })
          setProfileImageUrl(userInfo.profileImageUrl ?? null)
          setUserInfoUpdated(true)
        } else {
          setUserInfoUpdated(false)
        }
      })
      .catch(() => {
        setFetchLoading(false)
      })
  }, [userId, loggedInUserId, navigate])

  const {
    status: updateStatus,
    error: updateError,
    refetch
  } = useQuery({
    queryKey: [userId, userInfoUpdate],
    queryFn: async () => { await updateUserInfo(userId, userInfoUpdate) },
    onSuccess: () => { navigate(`/profile/${userId}`) },
    enabled: false
  })

  return (
    <Box>
      {
        fetchLoading
          ? <CustomAlert severity='info'>
            정보를 가져오는 중
          </CustomAlert>
          : updateStatus === 'loading'
            ? <CustomAlert severity='info'>
              정보를 수정하는 중
            </CustomAlert>
            : updateStatus === 'error'
              ? <CustomAlert severity='error'>
                정보를 수정할 수 없습니다: {updateError?.toString()}
              </CustomAlert>
              : <UserInfoEdit
                userInfoUpdated={userInfoUpdated}
                userInfoUpdate={userInfoUpdate}
                profileImageUrl={profileImageUrl}
                setProfileImageUrl={setProfileImageUrl}
                dispatch={dispatch}
              />
      }
      <Box
        width='100%'
      >
        <Container
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <EditButton
            onClick={() => { void refetch() }}
            disabled={!valid}
          >
            수정하기
          </EditButton>
        </Container>
      </Box>
    </Box>
  )
}

export default UserInfoEditScreen
