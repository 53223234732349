import {
  Grid,
  Paper,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import { type Keyword } from '../../../api'
import UserInfoAttr from './UserInfoAttr'

function BasicInfoDisplay ({
  major, keywordList, birthday, residence, lastUpdateTime
}: {
  major?: string
  keywordList?: Keyword[]
  birthday?: string
  residence?: string
  lastUpdateTime?: string
}): JSX.Element {
  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            기본 정보
          </Typography>
        </Grid>
        {/* major */}
        <Grid item xs={12}>
          <UserInfoAttr name='전공' value={major ?? '-'}/>
        </Grid>
        {/* keysordList */}
        <Grid item xs={12}>
          <UserInfoAttr name='키워드' value={
            keywordList !== undefined &&
              keywordList !== null &&
              keywordList.length > 0
              ? keywordList
                .map(keyword => keyword.content)
                .reduce((prev, curr) => `${prev}, ${curr}`)
              : '-'
          }/>
        </Grid>
        {/* birthdaty */}
        <Grid item xs={6}>
          <UserInfoAttr name='생년월일' value={
            birthday !== undefined && birthday !== null
              ? dayjs(birthday).format('YYYY. MM. DD.')
              : '-'
          }/>
        </Grid>
        {/* residence */}
        <Grid item xs={6}>
          <UserInfoAttr name='현재 거주지' value={residence ?? '-'}/>
        </Grid>
        {/* lastUpdateTime */}
        <Grid item xs={12}>
          <UserInfoAttr name='마지막 수정 시각' value={
            lastUpdateTime !== undefined && lastUpdateTime !== null
              ? new Date(lastUpdateTime + 'Z').toLocaleString('ko-KR', {
                timeZone: 'Asia/Seoul',
                timeZoneName: 'shortGeneric'
              })
              : '-'
          }/>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BasicInfoDisplay
