// StyledComponents.tsx
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'

export const LoginTextField = styled(TextField)({
  margin: 'normal',
  variant: 'outlined',
  marginBottom: '12px'
})

export const LoginButton = styled(Button)({
  // Add your common Button styles here
  variant: 'contained',
  backgroundColor: '#FFA500',
  color: '#ffffff',
  marginTop: '4px',
  marginBottom: '18px',
  height: '50px',
  '&:hover': {
    backgroundColor: '#808080'
  }
})

export const LogoutButton = styled(Button)({
  variant: 'contained',
  color: 'secondary'
})

// Add other common styled components as needed
