import {
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { type Experience } from '../../../api'

function ExperienceDisplay ({
  experienceList
}: {
  experienceList?: Experience[]
}): JSX.Element {
  if (experienceList === undefined) {
    return <div/>
  }

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
      {/* contactList */}
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            경력
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>기간</TableCell>
                  <TableCell align="center">기관</TableCell>
                  <TableCell align="center">내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {experienceList.map((experience) => (
                  <TableRow key={experience.id}>
                    <TableCell>{
                        experience.startYear !== undefined &&
                        experience.startYear !== null
                          ? `${experience.startYear}년`
                          : ''
                      } - {
                        experience.endYear !== undefined &&
                        experience.endYear !== null
                          ? `${experience.endYear}년`
                          : ''
                    }</TableCell>
                    <TableCell align="center">
                      {experience.company}
                    </TableCell>
                    <TableCell align="center">
                      {experience.content}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ExperienceDisplay
