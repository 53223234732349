// NotFoundScreen.tsx
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import logo from '../figures/logo_kr.png'

function NotFoundScreen (): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <img src={logo} alt="logo" className="logo" />
      <Typography variant="h4">
        페이지를 찾을 수 없습니다.
      </Typography>
      <Button component={Link} to="/home">
        <Typography variant="h6">
          홈으로 돌아가기
        </Typography>
      </Button>
    </Box>
  )
}

export default NotFoundScreen
