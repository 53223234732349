import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  styled,
  Typography
} from '@mui/material'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { Link } from 'react-router-dom'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

function CustomDrawer ({ drawerOpen, handleDrawerClose }: {
  drawerOpen: boolean
  handleDrawerClose: (event: React.MouseEvent<HTMLElement>) => void
}): JSX.Element {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key={1} disablePadding>
          <ListItemButton component={Link} to="/home">
            <ListItemText primary={'홈'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding>
          <ListItemButton component={Link} to="/search">
            <ListItemText primary={'검색'} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key = {1} disablePadding>
          <ListItemButton
            component="a" href="https://forms.gle/zUiJKxbsuAzby5o19"
            target="_blank" rel="noopener noreferrer">
            <ListItemText primary={'버그 제보'} />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding
          sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', alignItems: 'center' }}
          >
            © 2024 공과대학 우수학생센터 공우
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography
          variant="body2"
            sx={{ fontSize: '0.8rem', alignItems: 'center' }}
          >
            stem.eng.snu@gmail.com
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

export default CustomDrawer
