import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import React from 'react'

function SelectUserInfoEdit ({
  keyName, name, value, required, options, defaultValue, dispatch
}: {
  keyName: string
  name: string
  value: string
  required: boolean
  options: string[]
  defaultValue?: string
  dispatch: React.Dispatch<any>
}): JSX.Element {
  return (
    <Box sx={{ p: 0.5, m: 0.5 }}>
      <FormControl required={required} sx={{ display: 'flex' }}>
        <InputLabel
          id={`${name}-select-label`}
          error={required && value === ''}
        >{name}</InputLabel>
        <Select
          sx={{ display: 'flex' }}
          labelId={`${name}-select-label`}
          label={name + (required ? '*' : '')}
          value={value}
          error={required && value === ''}
          defaultValue={defaultValue}
          onChange={
            (evt) => {
              dispatch({
                type: 'plaintext',
                key: keyName,
                data: evt.target.value
              })
            }
          }
        >
          <MenuItem value=""></MenuItem>
          {
            options.map((option) =>
              <MenuItem key={option} value={option}>{option}</MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectUserInfoEdit
