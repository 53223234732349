import {
  Box,
  Typography
} from '@mui/material'
import ProfileImage from './ProfileImage'

function ProfileImageDisplay ({
  profileImageUrl, name, englishName, generation
}: {
  profileImageUrl?: string
  name: string
  englishName: string
  generation: string
}): JSX.Element {
  return (
    <Box>
      {/* profileImageUrl */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={2}
      >
        <ProfileImage
          profileImageUrl={profileImageUrl}
          sx={{ width: 175, height: 175 }}
        />
      </Box>
      {/* name + englishName */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={1}
      >
        <Typography variant='h5'>{
          `${
            name !== '' ? name : '???'
          } (${
            englishName !== '' ? englishName : '?'
          })`
        }
        </Typography>
      </Box>
      {/* generation */}
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={1}
      >
        <Typography variant='h6'>
          {`공우 ${generation !== '' ? generation : '?'}기`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ProfileImageDisplay
