import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthError, registerWithToken, validateEmail } from '../auth/auth'
import logo from '../figures/logo_kr.png'
import {
  RegisterButton,
  RegisterTextField
} from '../styles/RegisterScreen'
import '../styles/RegisterScreen.css'
import { CustomAlert } from '../styles/utils'

function RegisterScreen (): JSX.Element {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') ?? ''

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordCheck, setPasswordCheck] = useState<string>('')

  const isValid = validateEmail(email) &&
    password.length >= 8 &&
    password === passwordCheck

  const infoText = !validateEmail(email)
    ? '이메일이 올바르지 않습니다.'
    : password.length < 8
      ? '비밀번호가 너무 짧습니다.'
      : password !== passwordCheck
        ? '비밀번호가 일치하지 않습니다.'
        : ''

  const { data, status, error, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => await registerWithToken(email, password, token),
    cacheTime: 0,
    enabled: false,
    retry: 1
  })

  useEffect(() => {
    const user = data
    if (user !== null && user !== undefined) {
      navigate('/auth/login')
    }
  }, [data, navigate])

  return (
    <Box className="register-screen-container">
      <img src={logo} alt="logo" className="logo" />
      <RegisterTextField
        value={email}
        onChange={(evt) => { setEmail(evt.target.value) }}
        label="Email"
        className="input-field"
      />
      <RegisterTextField
        value={password}
        onChange={(evt) => { setPassword(evt.target.value) }}
        label="Password"
        type="password"
        className="password-field"
      />
      <RegisterTextField
        value={passwordCheck}
        onChange={(evt) => { setPasswordCheck(evt.target.value) }}
        label="Password (again)"
        type="password"
        className="password-field"
      />
      <RegisterButton
        onClick={() => { void refetch() }}
        className="register-button"
        disabled={!isValid}
      >
        Register
      </RegisterButton>
      {
        !isValid
          ? <CustomAlert severity="warning" className="register-pending">
              { infoText }
            </CustomAlert>
          : status === 'error'
            ? <CustomAlert severity="error" className="register-failed">
              { error instanceof AuthError ? error.message : 'Unknown error' }
            </CustomAlert>
            : status === 'loading'
              ? <CustomAlert severity="info" className="register-pending">
                회원가입을 시도하는 중
              </CustomAlert>
              : null
      }
    </Box>
  )
}

export default RegisterScreen
