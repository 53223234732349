import {
  Grid
} from '@mui/material'
import { type UserInfo } from '../../../api'
import AdditionalInfoDisplay from './AdditionalInfoDisplay'
import BasicInfoDisplay from './BasicInfoDisplay'
import ContactDisplay from './ContactDisplay'
import EducationDisplay from './EducationDisplay'
import ExperienceDisplay from './ExperienceDisplay'
import ExternalLinksDisplay from './ExternalLinksDisplay'
import ProfileImageDisplay from './ProfileImageDisplay'

function UserInfoDisplay ({ userInfo }: {
  userInfo: UserInfo
}): JSX.Element {
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ProfileImageDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ExternalLinksDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <BasicInfoDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ExperienceDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <EducationDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ContactDisplay {...userInfo}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <AdditionalInfoDisplay {...userInfo}/>
      </Grid>
    </Grid>
  )
}

export default UserInfoDisplay
