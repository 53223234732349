// Import the functions you need from the SDKs you need
// import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  browserSessionPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth'
import config from '../config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = initializeApp(config.firebaseConfig)
// const analytics = getAnalytics(app)
export const auth = getAuth(app)
setPersistence(auth, browserSessionPersistence)
  .catch(() => {
  })
