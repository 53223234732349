import {
  Box,
  TextField
} from '@mui/material'
import React from 'react'

function TextUserInfoEdit ({
  keyName, name, value, required, helperText, dispatch, valid
}: {
  keyName: string
  name: string
  value: string
  required: boolean
  helperText?: string
  dispatch: React.Dispatch<any>
  valid?: boolean
}): JSX.Element {
  return (
    <Box sx={{ p: 0.5, m: 0.5 }}>
      <TextField
        sx={{ display: 'flex' }}
        label={name}
        value={value}
        required={required}
        error={(required && value === '') || valid === false}
        onChange={
          (evt) => {
            dispatch({
              type: 'plaintext',
              key: keyName,
              data: evt.target.value
            })
          }
        }
        helperText={helperText}
      />
    </Box>
  )
}

export default TextUserInfoEdit
