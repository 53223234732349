import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { auth } from './firebase'

function LoginRequired (): JSX.Element {
  const navigate = useNavigate()
  const [status, setStatus] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => {
    auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser === null) {
        navigate('/auth/login')
      } else {
        setStatus(true)
        setUserId(firebaseUser.uid)
      }
    })
  })

  return (
    <div>
      { status && <Outlet context={{ userId }}/>}
    </div>
  )
}

export default LoginRequired
