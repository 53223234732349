import {
  Grid,
  Paper,
  Typography
} from '@mui/material'
import React from 'react'
import { type KeywordUpdate } from '../../../api'
import DateUserInfoEdit from './DateUserInfoEdit'
import KeywordListUserInfoEdit from './KeywordListUserInfoEdit'
import SelectUserInfoEdit from './SelectUserInfoEdit'
import TextUserInfoEdit from './TextUserInfoEdit'
import {
  type UserInfoUpdateAction
} from './userInfoReducer'

function SimpleUserInfoEdit ({
  name,
  englishName,
  generation,
  major,
  residence,
  birthday,
  keywordUpdateList,
  dispatch
}: {
  name: string
  englishName: string
  generation: string
  major?: string
  residence?: string
  birthday?: string
  keywordUpdateList?: KeywordUpdate[]
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  const year = (new Date()).getFullYear() - 2009
  const generationList = Array.from(Array(year < 10 ? year : 2 * year - 9))
    .map((_, n) => String(n < 9 ? n + 1 : (n + 11) / 2))

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5 }}>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            기본 정보
          </Typography>
        </Grid>
        {/* name */}
        <Grid item xs={3}>
          <TextUserInfoEdit
            keyName='name'
            name='이름'
            value={name ?? ''}
            dispatch={dispatch}
            required={true}
          />
        </Grid>
        {/* name */}
        <Grid item xs={6}>
          <TextUserInfoEdit
            keyName='englishName'
            name='영문 이름'
            value={englishName ?? ''}
            dispatch={dispatch}
            required={true}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectUserInfoEdit
            keyName='generation'
            name='기수'
            value={generation ?? ''}
            options={generationList}
            dispatch={dispatch}
            required={true}
          />
        </Grid>
        {/* major */}
        <Grid item xs={12}>
          <TextUserInfoEdit
            keyName='major'
            name='전공'
            value={major ?? ''}
            dispatch={dispatch}
            required={true}
          />
        </Grid>
        {/* residence */}
        <Grid item xs={6}>
          <TextUserInfoEdit
            keyName='residence'
            name='현재 거주지'
            value={residence ?? ''}
            dispatch={dispatch}
            required={false}
          />
        </Grid>
        {/* birthday */}
        <Grid item xs={6}>
          <DateUserInfoEdit
            keyName='birthday'
            name='생년월일'
            value={birthday ?? undefined}
            dispatch={dispatch}
          />
        </Grid>
        {/* keywordList */}
        <Grid item xs={12}>
          <KeywordListUserInfoEdit
            name='키워드'
            value={
              keywordUpdateList !== undefined && keywordUpdateList.length > 0
                ? keywordUpdateList
                  .map(keywordUpdate => keywordUpdate.content)
                  .reduce((prev, curr) => `${prev},${curr}`)
                : ''
            }
            dispatch={dispatch}
            required={false}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SimpleUserInfoEdit
