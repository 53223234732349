import {
  type ContactUpdate,
  type EducationUpdate,
  type ExperienceUpdate,
  type KeywordUpdate, type UserInfo, type UserInfoUpdate
} from '../../../api'

export interface InitUserIntoAction {
  type: 'init'
  userInfo: UserInfo
}

export interface PlainTextUserInfoUpdateAction {
  type: 'plaintext'
  key: 'name' | 'englishName' | 'generation'
  | 'major' | 'residence' | 'birthday' | 'additionalInfo'
  data?: string
}

export interface KeywordListUserInfoUpdateAction {
  type: 'keywordList'
  keywordListStr: string
}

export type ListUserInfoUpdateAction = {
  type: 'list-append'
  key: 'experienceUpdateList' | 'educationUpdateList' | 'contactUpdateList'
  data: ExperienceUpdate | EducationUpdate | ContactUpdate
} | {
  type: 'list-remove'
  key: 'experienceUpdateList' | 'educationUpdateList' | 'contactUpdateList'
  data: ExperienceUpdate | EducationUpdate | ContactUpdate
  idx: number
}

export type UserInfoUpdateAction =
  InitUserIntoAction
  | PlainTextUserInfoUpdateAction
  | KeywordListUserInfoUpdateAction
  | ListUserInfoUpdateAction

function listAppend<T> (list: T[] | undefined, item: T): T[] {
  if (list === undefined) {
    return [item]
  } else {
    return [...list, item]
  }
}

function listRemove<T> (list: T[] | undefined, item: T, idx: number): T[] {
  if (list === undefined) {
    return []
  } else if (idx < 0 || idx >= list.length) {
    return list
  } else {
    if (item === list[idx]) {
      list.splice(idx, 1)
    }
    return list
  }
}

export function userInfoReducer (
  userInfoUpdate: UserInfoUpdate,
  action: UserInfoUpdateAction
): UserInfoUpdate {
  if (action.type === 'init') {
    const userInfo = action.userInfo
    return {
      ...userInfo,
      contactUpdateList: userInfo.contactList
        ?.map((contact): ContactUpdate => ({
          ...contact
        })),
      educationUpdateList: userInfo.educationList
        ?.map((education): EducationUpdate => ({
          ...education
        })),
      experienceUpdateList: userInfo.experienceList
        ?.map((experience): ExperienceUpdate => ({
          ...experience
        })),
      keywordUpdateList: userInfo.keywordList
        ?.map((keyword): KeywordUpdate => ({
          ...keyword
        }))
    }
  } if (action.type === 'plaintext') {
    return { ...userInfoUpdate, [action.key]: action.data }
  } else if (action.type === 'keywordList') {
    return {
      ...userInfoUpdate,
      keywordUpdateList: action.keywordListStr
        .split(',')
        .map((content) => ({ content }))
    }
  } else if (action.type === 'list-append') {
    return {
      ...userInfoUpdate,
      [action.key]: listAppend(userInfoUpdate[action.key], action.data)
    }
  } else if (action.type === 'list-remove') {
    return {
      ...userInfoUpdate,
      [action.key]: listRemove(
        userInfoUpdate[action.key], action.data, action.idx
      )
    }
  } else {
    return userInfoUpdate
  }
}
