import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

function ListRemoveDialog ({
  open, onConfirm, onCancel
}: {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}): JSX.Element {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>삭제</DialogTitle>
      <DialogContent>
        <DialogContentText>
          정말 삭제하시겠습니까?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          sx={{ color: '#FFA500' }}
        >
          취소
        </Button>
        <Button
          onClick={onConfirm}
          sx={{ color: '#FFA500' }}
          autoFocus
        >
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ListRemoveDialog
