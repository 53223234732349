import { Avatar, type SxProps } from '@mui/material'

function ProfileImage ({ sx, profileImageUrl }: {
  sx?: SxProps
  profileImageUrl: string | null | undefined
}): JSX.Element {
  return (
    <Avatar
      src={profileImageUrl ?? undefined}
      sx={sx}
    />
  )
}

export default ProfileImage
