/* tslint:disable */
/* eslint-disable */
/**
 * STEM Intranet Backend (dev)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 연락처를 나타내고 저장하기 위한 모델.
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {ContactType}
     * @memberof Contact
     */
    'contactType': ContactType;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    'id': number;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ContactType = {
    Phone: 'PHONE',
    Email: 'EMAIL',
    Other: 'OTHER'
} as const;

export type ContactType = typeof ContactType[keyof typeof ContactType];


/**
 * 연락처를 수정하기 위한 모델.
 * @export
 * @interface ContactUpdate
 */
export interface ContactUpdate {
    /**
     * 
     * @type {ContactType}
     * @memberof ContactUpdate
     */
    'contactType': ContactType;
    /**
     * 
     * @type {string}
     * @memberof ContactUpdate
     */
    'content': string;
}


/**
 * 전공을 나타내고 저장하기 위한 모델.
 * @export
 * @interface Education
 */
export interface Education {
    /**
     * 
     * @type {string}
     * @memberof Education
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof Education
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof Education
     */
    'endYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof Education
     */
    'id': number;
}
/**
 * 전공을 수정하기 위한 모델.
 * @export
 * @interface EducationUpdate
 */
export interface EducationUpdate {
    /**
     * 
     * @type {string}
     * @memberof EducationUpdate
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof EducationUpdate
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof EducationUpdate
     */
    'endYear'?: number;
}
/**
 * 경력을 나타내고 저장하기 위한 모델.
 * @export
 * @interface Experience
 */
export interface Experience {
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof Experience
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof Experience
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof Experience
     */
    'endYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof Experience
     */
    'id': number;
}
/**
 * 경력을 수정하기 위한 모델.
 * @export
 * @interface ExperienceUpdate
 */
export interface ExperienceUpdate {
    /**
     * 
     * @type {string}
     * @memberof ExperienceUpdate
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof ExperienceUpdate
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof ExperienceUpdate
     */
    'startYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceUpdate
     */
    'endYear'?: number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 키워드를 나타내고 저장하기 위한 모델.
 * @export
 * @interface Keyword
 */
export interface Keyword {
    /**
     * 
     * @type {string}
     * @memberof Keyword
     */
    'content': string;
    /**
     * 
     * @type {number}
     * @memberof Keyword
     */
    'id': number;
}
/**
 * 키워드를 수정하기 위한 모델.
 * @export
 * @interface KeywordUpdate
 */
export interface KeywordUpdate {
    /**
     * 
     * @type {string}
     * @memberof KeywordUpdate
     */
    'content': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SearchTag = {
    Name: 'name',
    EnglishName: 'english_name',
    Generation: 'generation',
    Experience: 'experience',
    Education: 'education',
    Major: 'major',
    Keyword: 'keyword',
    Residence: 'residence',
    General: 'general'
} as const;

export type SearchTag = typeof SearchTag[keyof typeof SearchTag];


/**
 * 사용자의 로그인 정보를 나타내는 모델.
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userId': string;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    'userType': UserType;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isPasswordChanged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isUserInfoUpdated': boolean;
}


/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'password': string;
}
/**
 * 사용자의 개인정보를 나타내고 저장하기 위한 모델.
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'englishName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'generation': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'major': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'googleScholarUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'homepageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'residence'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastUpdateTime': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {Array<Experience>}
     * @memberof UserInfo
     */
    'experienceList'?: Array<Experience>;
    /**
     * 
     * @type {Array<Education>}
     * @memberof UserInfo
     */
    'educationList'?: Array<Education>;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof UserInfo
     */
    'contactList'?: Array<Contact>;
    /**
     * 
     * @type {Array<Keyword>}
     * @memberof UserInfo
     */
    'keywordList'?: Array<Keyword>;
}
/**
 * 사용자의 개인정보를 수정하기 위한 모델.
 * @export
 * @interface UserInfoUpdate
 */
export interface UserInfoUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'englishName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'generation': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'major': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'googleScholarUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'homepageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'residence'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdate
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {Array<ExperienceUpdate>}
     * @memberof UserInfoUpdate
     */
    'experienceUpdateList'?: Array<ExperienceUpdate>;
    /**
     * 
     * @type {Array<EducationUpdate>}
     * @memberof UserInfoUpdate
     */
    'educationUpdateList'?: Array<EducationUpdate>;
    /**
     * 
     * @type {Array<ContactUpdate>}
     * @memberof UserInfoUpdate
     */
    'contactUpdateList'?: Array<ContactUpdate>;
    /**
     * 
     * @type {Array<KeywordUpdate>}
     * @memberof UserInfoUpdate
     */
    'keywordUpdateList'?: Array<KeywordUpdate>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const UserType = {
    Admin: 'ADMIN',
    User: 'USER'
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AdminTokenApi - axios parameter creator
 * @export
 */
export const AdminTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 주어진 `data`를 encode한 custom token을 반환합니다.`
         * @summary Get Token
         * @param {number} expiresInSeconds 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (expiresInSeconds: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expiresInSeconds' is not null or undefined
            assertParamExists('getToken', 'expiresInSeconds', expiresInSeconds)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getToken', 'body', body)
            const localVarPath = `/admin/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (expiresInSeconds !== undefined) {
                localVarQueryParameter['expiresInSeconds'] = expiresInSeconds;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTokenApi - functional programming interface
 * @export
 */
export const AdminTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 주어진 `data`를 encode한 custom token을 반환합니다.`
         * @summary Get Token
         * @param {number} expiresInSeconds 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(expiresInSeconds: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(expiresInSeconds, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminTokenApi.getToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AdminTokenApi - factory interface
 * @export
 */
export const AdminTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTokenApiFp(configuration)
    return {
        /**
         * 주어진 `data`를 encode한 custom token을 반환합니다.`
         * @summary Get Token
         * @param {number} expiresInSeconds 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(expiresInSeconds: number, body: object, options?: any): AxiosPromise<string> {
            return localVarFp.getToken(expiresInSeconds, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTokenApi - object-oriented interface
 * @export
 * @class AdminTokenApi
 * @extends {BaseAPI}
 */
export class AdminTokenApi extends BaseAPI {
    /**
     * 주어진 `data`를 encode한 custom token을 반환합니다.`
     * @summary Get Token
     * @param {number} expiresInSeconds 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTokenApi
     */
    public getToken(expiresInSeconds: number, body: object, options?: RawAxiosRequestConfig) {
        return AdminTokenApiFp(this.configuration).getToken(expiresInSeconds, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminUserApi - axios parameter creator
 * @export
 */
export const AdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 새로운 관리자를 생성합니다.  Error * 같은 email을 가진 사용자가 이미 존재할 경우:   400 Bad Request \"Could not create user; user already exists.\"
         * @summary Admin Create Admin User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAdminUser: async (userCreate: UserCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('adminCreateAdminUser', 'userCreate', userCreate)
            const localVarPath = `/admin/user/create-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Admin Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser: async (userCreate: UserCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('adminCreateUser', 'userCreate', userCreate)
            const localVarPath = `/admin/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주어진 `userId`를 가진 사용자를 삭제하고, 해당하는 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not delete user; user does not exist.\"
         * @summary Admin Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminDeleteUser', 'userId', userId)
            const localVarPath = `/admin/user/{userId}/delete`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserApi - functional programming interface
 * @export
 */
export const AdminUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 새로운 관리자를 생성합니다.  Error * 같은 email을 가진 사용자가 이미 존재할 경우:   400 Bad Request \"Could not create user; user already exists.\"
         * @summary Admin Create Admin User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateAdminUser(userCreate: UserCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateAdminUser(userCreate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminUserApi.adminCreateAdminUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Admin Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateUser(userCreate: UserCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateUser(userCreate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminUserApi.adminCreateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 주어진 `userId`를 가진 사용자를 삭제하고, 해당하는 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not delete user; user does not exist.\"
         * @summary Admin Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteUser(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminUserApi.adminDeleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AdminUserApi - factory interface
 * @export
 */
export const AdminUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserApiFp(configuration)
    return {
        /**
         * 새로운 관리자를 생성합니다.  Error * 같은 email을 가진 사용자가 이미 존재할 경우:   400 Bad Request \"Could not create user; user already exists.\"
         * @summary Admin Create Admin User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateAdminUser(userCreate: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.adminCreateAdminUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Admin Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateUser(userCreate: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.adminCreateUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 주어진 `userId`를 가진 사용자를 삭제하고, 해당하는 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not delete user; user does not exist.\"
         * @summary Admin Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminDeleteUser(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
export class AdminUserApi extends BaseAPI {
    /**
     * 새로운 관리자를 생성합니다.  Error * 같은 email을 가진 사용자가 이미 존재할 경우:   400 Bad Request \"Could not create user; user already exists.\"
     * @summary Admin Create Admin User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public adminCreateAdminUser(userCreate: UserCreate, options?: RawAxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).adminCreateAdminUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 새로운 사용자를 생성합니다.
     * @summary Admin Create User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public adminCreateUser(userCreate: UserCreate, options?: RawAxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).adminCreateUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주어진 `userId`를 가진 사용자를 삭제하고, 해당하는 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not delete user; user does not exist.\"
     * @summary Admin Delete User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public adminDeleteUser(userId: string, options?: RawAxiosRequestConfig) {
        return AdminUserApiFp(this.configuration).adminDeleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _default: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _default(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._default(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi._default']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _default(options?: any): AxiosPromise<object> {
            return localVarFp._default(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public _default(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration)._default(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 주어진 `query`와 `search_tag`를 가진 사용자의 list[`UserInfo`] object를 반환합니다.
         * @summary Get Profile By Query And Tag
         * @param {SearchTag} [searchTag] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByQueryAndTag: async (searchTag?: SearchTag, query?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchTag !== undefined) {
                localVarQueryParameter['searchTag'] = searchTag;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 주어진 `query`와 `search_tag`를 가진 사용자의 list[`UserInfo`] object를 반환합니다.
         * @summary Get Profile By Query And Tag
         * @param {SearchTag} [searchTag] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileByQueryAndTag(searchTag?: SearchTag, query?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileByQueryAndTag(searchTag, query, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SearchApi.getProfileByQueryAndTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 주어진 `query`와 `search_tag`를 가진 사용자의 list[`UserInfo`] object를 반환합니다.
         * @summary Get Profile By Query And Tag
         * @param {SearchTag} [searchTag] 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByQueryAndTag(searchTag?: SearchTag, query?: string, options?: any): AxiosPromise<Array<UserInfo>> {
            return localVarFp.getProfileByQueryAndTag(searchTag, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 주어진 `query`와 `search_tag`를 가진 사용자의 list[`UserInfo`] object를 반환합니다.
     * @summary Get Profile By Query And Tag
     * @param {SearchTag} [searchTag] 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getProfileByQueryAndTag(searchTag?: SearchTag, query?: string, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).getProfileByQueryAndTag(searchTag, query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change password; user does not exist.\"
         * @summary Change Password
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (userId: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserPassword', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changeUserPassword', 'body', body)
            const localVarPath = `/user/{userId}/change-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Create User With Token
         * @param {string} token 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserWithToken: async (token: string, userCreate: UserCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('createUserWithToken', 'token', token)
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUserWithToken', 'userCreate', userCreate)
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주어진 `userId`를 가진 사용자의 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우: 404 Not Found \"Could not find user\"
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/user/{userId}/get`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호가 바뀌었음을 알립니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not set password changed; user does not exist.\"
         * @summary Set Password Changed
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordChanged: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserPasswordChanged', 'userId', userId)
            const localVarPath = `/user/{userId}/set-password-changed`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change password; user does not exist.\"
         * @summary Change Password
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(userId: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(userId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.changeUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Create User With Token
         * @param {string} token 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserWithToken(token: string, userCreate: UserCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserWithToken(token, userCreate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createUserWithToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 주어진 `userId`를 가진 사용자의 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우: 404 Not Found \"Could not find user\"
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호가 바뀌었음을 알립니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not set password changed; user does not exist.\"
         * @summary Set Password Changed
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserPasswordChanged(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserPasswordChanged(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.setUserPasswordChanged']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change password; user does not exist.\"
         * @summary Change Password
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(userId: string, body: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.changeUserPassword(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 새로운 사용자를 생성합니다.
         * @summary Create User With Token
         * @param {string} token 
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserWithToken(token: string, userCreate: UserCreate, options?: any): AxiosPromise<User> {
            return localVarFp.createUserWithToken(token, userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 주어진 `userId`를 가진 사용자의 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우: 404 Not Found \"Could not find user\"
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 주어진 `userId`를 가진 사용자의 비밀번호가 바뀌었음을 알립니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not set password changed; user does not exist.\"
         * @summary Set Password Changed
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserPasswordChanged(userId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.setUserPasswordChanged(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 주어진 `userId`를 가진 사용자의 비밀번호를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change password; user does not exist.\"
     * @summary Change Password
     * @param {string} userId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeUserPassword(userId: string, body: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).changeUserPassword(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 새로운 사용자를 생성합니다.
     * @summary Create User With Token
     * @param {string} token 
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserWithToken(token: string, userCreate: UserCreate, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserWithToken(token, userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주어진 `userId`를 가진 사용자의 `User` object를 반환합니다.  Error * 해당하는 사용자가 없을 경우: 404 Not Found \"Could not find user\"
     * @summary Get User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주어진 `userId`를 가진 사용자의 비밀번호가 바뀌었음을 알립니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not set password changed; user does not exist.\"
     * @summary Set Password Changed
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setUserPasswordChanged(userId: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).setUserPasswordChanged(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 삭제합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Delete User Profile Image
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileImage: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserProfileImage', 'userId', userId)
            const localVarPath = `/user/{userId}/delete-profile-image/`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Could not find user with given user ID. 주어진 `userId`를 가진 사용자의 UserInfo 를 반환합니다.  Error * 해당하는 사용자가 없을 경우:     404 Not Found \"Could not find user with given user ID.\"
         * @summary Get User Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInfo', 'userId', userId)
            const localVarPath = `/user/{userId}/user-info`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주어진 `userId`를 가진 사용자의 정보를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change user info; user does not exist.\"
         * @summary Update User Info
         * @param {string} userId 
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo: async (userId: string, userInfoUpdate: UserInfoUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserInfo', 'userId', userId)
            // verify required parameter 'userInfoUpdate' is not null or undefined
            assertParamExists('updateUserInfo', 'userInfoUpdate', userInfoUpdate)
            const localVarPath = `/user/{userId}/update-user-info`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInfoUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 업로드 합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Update User Profile Image
         * @param {string} userId 
         * @param {File} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileImage: async (userId: string, imageFile: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserProfileImage', 'userId', userId)
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('updateUserProfileImage', 'imageFile', imageFile)
            const localVarPath = `/user/{userId}/update-profile-image/`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 삭제합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Delete User Profile Image
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileImage(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileImage(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserInfoApi.deleteUserProfileImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Could not find user with given user ID. 주어진 `userId`를 가진 사용자의 UserInfo 를 반환합니다.  Error * 해당하는 사용자가 없을 경우:     404 Not Found \"Could not find user with given user ID.\"
         * @summary Get User Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserInfoApi.getUserInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 주어진 `userId`를 가진 사용자의 정보를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change user info; user does not exist.\"
         * @summary Update User Info
         * @param {string} userId 
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfo(userId: string, userInfoUpdate: UserInfoUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInfo(userId, userInfoUpdate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserInfoApi.updateUserInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 업로드 합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Update User Profile Image
         * @param {string} userId 
         * @param {File} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileImage(userId: string, imageFile: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileImage(userId, imageFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserInfoApi.updateUserProfileImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInfoApiFp(configuration)
    return {
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 삭제합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Delete User Profile Image
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileImage(userId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserProfileImage(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Could not find user with given user ID. 주어진 `userId`를 가진 사용자의 UserInfo 를 반환합니다.  Error * 해당하는 사용자가 없을 경우:     404 Not Found \"Could not find user with given user ID.\"
         * @summary Get User Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(userId: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 주어진 `userId`를 가진 사용자의 정보를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change user info; user does not exist.\"
         * @summary Update User Info
         * @param {string} userId 
         * @param {UserInfoUpdate} userInfoUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo(userId: string, userInfoUpdate: UserInfoUpdate, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.updateUserInfo(userId, userInfoUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 주어진 `userId`를 가진 사용자의 프로필 이미지를 업로드 합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
         * @summary Update User Profile Image
         * @param {string} userId 
         * @param {File} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileImage(userId: string, imageFile: File, options?: any): AxiosPromise<string> {
            return localVarFp.updateUserProfileImage(userId, imageFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
    /**
     * 주어진 `userId`를 가진 사용자의 프로필 이미지를 삭제합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
     * @summary Delete User Profile Image
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public deleteUserProfileImage(userId: string, options?: RawAxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).deleteUserProfileImage(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Could not find user with given user ID. 주어진 `userId`를 가진 사용자의 UserInfo 를 반환합니다.  Error * 해당하는 사용자가 없을 경우:     404 Not Found \"Could not find user with given user ID.\"
     * @summary Get User Info
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public getUserInfo(userId: string, options?: RawAxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).getUserInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주어진 `userId`를 가진 사용자의 정보를 변경합니다.  Error * 해당하는 사용자가 없을 경우:   404 Not Found \"Could not change user info; user does not exist.\"
     * @summary Update User Info
     * @param {string} userId 
     * @param {UserInfoUpdate} userInfoUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public updateUserInfo(userId: string, userInfoUpdate: UserInfoUpdate, options?: RawAxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).updateUserInfo(userId, userInfoUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주어진 `userId`를 가진 사용자의 프로필 이미지를 업로드 합니다.  반드시 UserInfo를 먼저 업데이트한 이후 호출해야 합니다.  Error * UserInfo가 아직 업데이트되지 않았을 경우:     404 Not Found \"Could not update profile image; update user info first.\"
     * @summary Update User Profile Image
     * @param {string} userId 
     * @param {File} imageFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInfoApi
     */
    public updateUserProfileImage(userId: string, imageFile: File, options?: RawAxiosRequestConfig) {
        return UserInfoApiFp(this.configuration).updateUserProfileImage(userId, imageFile, options).then((request) => request(this.axios, this.basePath));
    }
}



