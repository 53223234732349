// HomeScreen.tsx
import { Box, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SearchBox from '../components/SearchBox'
import { LogoBox } from '../styles/HomeScreen'
import logo from '../figures/logo_kr.png'

function HomeScreen (): JSX.Element {
  const navigate = useNavigate()
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='80%'
      width='100%'
    >
      <Container>
        <LogoBox>
          <img src={logo} alt='logo' width='90%' />
        </LogoBox>
        <br/>
        <Box onClick={() => { navigate('/search') }}>
          <SearchBox/>
        </Box>
      </Container>
    </Box>
  )
}

export default HomeScreen
