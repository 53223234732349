import {
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { type Education } from '../../../api'

function EducationDisplay ({
  educationList
}: {
  educationList?: Education[]
}): JSX.Element {
  if (educationList === undefined) {
    return <div/>
  }

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
      {/* contactList */}
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            학력
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>기간</TableCell>
                  <TableCell align="center">내용</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {educationList.map((education) => (
                  <TableRow key={education.id}>
                    <TableCell>{
                        education.startYear !== undefined &&
                        education.startYear !== null
                          ? `${education.startYear}년`
                          : ''
                      } - {
                        education.endYear !== undefined &&
                        education.endYear !== null
                          ? `${education.endYear}년`
                          : ''
                    }</TableCell>
                    <TableCell align="center">
                      {education.content}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EducationDisplay
