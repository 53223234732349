import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useReducer } from 'react'
import { type EducationUpdate } from '../../../api'
import TextUserInfoEdit from './TextUserInfoEdit'

export interface EducationUpdateAction {
  type: 'plaintext'
  key: 'content' | 'startYear' | 'endYear'
  data: string
}

function educationUpdateReducer (
  educationUpdate: EducationUpdate,
  action: EducationUpdateAction
): EducationUpdate {
  if (action.key === 'startYear' || action.key === 'endYear') {
    const n = action.data !== '' && Number(action.data)
    return {
      ...educationUpdate,
      [action.key]: Number.isInteger(n) ? n : undefined
    }
  } else {
    return {
      ...educationUpdate,
      [action.key]: action.data
    }
  }
}

function EducationAppendDialog ({
  open, onConfirm, onCancel
}: {
  open: boolean
  onConfirm: (item: EducationUpdate) => void
  onCancel: () => void
}): JSX.Element {
  const [educationUpdate, dispatch] = useReducer(
    educationUpdateReducer,
    {
      content: ''
    }
  )

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>학력 추가</DialogTitle>
      <DialogContent>
        <TextUserInfoEdit
          keyName='content'
          name='내용'
          value={educationUpdate.content}
          required={true}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='startYear'
          name='시작 연도'
          value={String(educationUpdate.startYear ?? '')}
          required={false}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='endYear'
          name='끝 연도'
          value={String(educationUpdate.endYear ?? '')}
          required={false}
          dispatch={dispatch}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          sx={{ color: '#FFA500' }}
        >
          취소
        </Button>
        <Button
          onClick={() => { onConfirm(educationUpdate) }}
          sx={{ color: '#FFA500' }}
          disabled={
            educationUpdate.content === ''
          }
          autoFocus
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EducationAppendDialog
