import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { auth } from './firebase'

function LogoutRequired (): JSX.Element {
  const navigate = useNavigate()
  const [status, setStatus] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user !== null) {
        navigate('/home')
      }
      setStatus(true)
    })
  })

  return (
    <div>
      { status && <Outlet/>}
    </div>
  )
}

export default LogoutRequired
