// HomeScreen.styles.tsx
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

export const ScreenBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100dvh',
  position: 'relative'
})

export const ButtonBox = styled(Box)({
  position: 'absolute',
  top: '10px',
  right: '10px'
})

export const CustomAppBar = styled(AppBar)({
  backgroundColor: '#2196F3', // You can use your desired color
  width: '100%',
  zIndex: 1
})
