import {
  Paper,
  Typography
} from '@mui/material'

function UserInfoAttr ({ name, value }: {
  name: string
  value: string
}): JSX.Element {
  return (
    <Paper sx={{ p: 0.5, m: 0.5 }}>
      <Typography variant="body2" sx={{
        fontWeight: 'bold'
      }}>{ name }</Typography>
      <Typography variant="body1">{ value }</Typography>
    </Paper>
  )
}

export default UserInfoAttr
