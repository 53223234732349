import { getCurrentFirebaseUser } from '../auth/auth'
import config from '../config'
import { Configuration } from './configuration'

const apiConfig = new Configuration({
  basePath: config.apiServer,
  apiKey: async (): Promise<string> => {
    try {
      const firebaseUser = getCurrentFirebaseUser()
      if (firebaseUser !== null) {
        return await firebaseUser.getIdToken()
      }
      return ''
    } catch (err) {
      return ''
    }
  }
})

export default apiConfig
