import { Box } from '@mui/material'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import { UserApi } from '../api'
import apiConfig from '../api/config'
import { AuthError } from '../auth/auth'
import {
  ChangePasswordButton,
  ChangePasswordTextField
} from '../styles/ChangePasswordScreen'
import '../styles/ChangePasswordScreen.css'
import { CustomAlert } from '../styles/utils'

const handleChangePassword = async (
  userId: string,
  password: string
): Promise<void> => {
  const userApi = new UserApi(apiConfig)
  await userApi.changeUserPassword(userId, password)
}

interface UserIdContext {
  userId: string
}

function ChangePasswordScreen (): JSX.Element {
  const { userId } = useOutletContext<UserIdContext>()
  const queryClient = useQueryClient()
  const [password, setPassword] = useState<string>('')
  const [passwordCheck, setPasswordCheck] = useState<string>('')

  const isValid = password.length >= 8 &&
    password === passwordCheck

  const infoText = password.length < 8
    ? '비밀번호가 너무 짧습니다.'
    : password !== passwordCheck
      ? '비밀번호가 일치하지 않습니다.'
      : ''

  const { status, error, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => { await handleChangePassword(userId, password) },
    cacheTime: 0,
    enabled: false,
    retry: 1
  })

  return (
    <Box className="change-password-screen-container">
      <ChangePasswordTextField
        value={password}
        onChange={(evt) => {
          setPassword(evt.target.value)
          if (status === 'success') {
            queryClient.clear()
          }
        }}
        label="Password"
        type="password"
        className="password-field"
      />
      <ChangePasswordTextField
        value={passwordCheck}
        onChange={(evt) => {
          setPasswordCheck(evt.target.value)
          if (status === 'success') {
            queryClient.clear()
          }
        }}
        label="Password (again)"
        type="password"
        className="password-field"
      />
      <ChangePasswordButton
        onClick={() => { void refetch() }}
        className="change-password-button"
        disabled={!isValid}
      >
        Change
      </ChangePasswordButton>
      {
        !isValid
          ? <CustomAlert
            severity="warning"
            className="change-password-pending"
          >
              { infoText }
            </CustomAlert>
          : status === 'error'
            ? <CustomAlert
              severity="error"
              className="change-password-failed"
            >
              { error instanceof AuthError ? error.message : 'Unknown error' }
            </CustomAlert>
            : status === 'loading'
              ? <CustomAlert
                severity="info"
                className="change-password-pending"
              >
                비밀번호를 변경하는 중
              </CustomAlert>
              : status === 'success'
                ? <CustomAlert
                  severity="success"
                  className="change-password-success"
                >
                  비밀번호를 변경했습니다.
                </CustomAlert>
                : null
      }
    </Box>
  )
}

export default ChangePasswordScreen
