import {
  Grid,
  Paper,
  Typography
} from '@mui/material'

function UserInfoAttr ({ name, value }: {
  name: string
  value: string
}): JSX.Element {
  return (
    <Paper sx={{ p: 0.5, m: 0.5 }}>
      <Typography variant="body2" sx={{
        fontWeight: 'bold'
      }}>{ name }</Typography>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
         { value }
      </Typography>
    </Paper>
  )
}

function AdditionalInfoDisplay ({ additionalInfo }: {
  additionalInfo?: string
}): JSX.Element {
  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            추가 정보
          </Typography>
        </Grid>
        {/* additionalInfo */}
        <Grid item xs={12}>
          <UserInfoAttr name='하고 싶은 말' value={additionalInfo ?? '-'}/>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AdditionalInfoDisplay
