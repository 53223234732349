// HomeScreen.tsx
import { Box, Toolbar } from '@mui/material'
import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import CustomAppbar from '../components/CustomAppbar'
import CustomDrawer from '../components/CustomDrawer'
import { ScreenBox } from '../styles/ScreenWrapper'

interface UserIdContext {
  userId: string
}

function ScreenWrapper (): JSX.Element {
  const { userId } = useOutletContext<UserIdContext>()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleDrawerOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = (): void => {
    setDrawerOpen(false)
  }

  return (
    <ScreenBox>
      <CustomAppbar
        handleDrawerOpen={handleDrawerOpen}
      />
      <CustomDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Toolbar/>
      <Box m={1}/>
      <Outlet context={{ userId }}>
      </Outlet>
    </ScreenBox>
  )
}

export default ScreenWrapper
