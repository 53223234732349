import { AddCircle, RemoveCircle } from '@mui/icons-material'
import {
  Container,
  FormHelperText,
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { type ContactUpdate } from '../../../api'
import ContactAppendDialog from './ContactAppendDialog'
import ListRemoveDialog from './ListRemoveDialog'
import { type UserInfoUpdateAction } from './userInfoReducer'

function ContactListEdit ({
  contactUpdateList,
  dispatch
}: {
  contactUpdateList: ContactUpdate[]
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [removeIdx, setRemoveIdx] = useState<number | null>(null)

  const handleRemove = (): void => {
    if (removeIdx !== null) {
      dispatch({
        type: 'list-remove',
        key: 'contactUpdateList',
        data: contactUpdateList[removeIdx],
        idx: removeIdx
      })
      setRemoveIdx(null)
    }
    setRemoveDialogOpen(false)
  }

  const cancelRemove = (): void => {
    setRemoveIdx(null)
    setRemoveDialogOpen(false)
  }

  const handleAppend = (item: ContactUpdate): void => {
    dispatch({
      type: 'list-append',
      key: 'contactUpdateList',
      data: item
    })
    setAddDialogOpen(false)
  }

  const cancelAppend = (): void => {
    setAddDialogOpen(false)
  }

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5 }}>
      <ListRemoveDialog
        open={removeDialogOpen}
        onConfirm={handleRemove}
        onCancel={cancelRemove}
      />
      <ContactAppendDialog
        open={addDialogOpen}
        onConfirm={handleAppend}
        onCancel={cancelAppend}
      />
      {/* contactList */}
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            연락처 *
            <FormHelperText error={contactUpdateList.length < 1}>
              하나 이상의 연락처를 입력해 주세요.
            </FormHelperText>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {contactUpdateList.map((contactUpdate, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      { contactUpdate.contactType }
                    </TableCell>
                    <TableCell align="center">
                      { contactUpdate.content }
                    </TableCell>
                    <TableCell align="center">
                      <RemoveCircle
                        sx={{ color: '#FFA500' }}
                        onClick={() => {
                          setRemoveIdx(i)
                          setRemoveDialogOpen(true)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12}>
            <Container sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
              <AddCircle sx={{ color: '#FFA500' }} onClick={() => {
                setAddDialogOpen(true)
              }}/>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContactListEdit
