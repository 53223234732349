import { useQuery } from 'react-query'
import { useOutletContext, useParams } from 'react-router-dom'
import { UserInfoApi, type UserInfo } from '../api'
import apiConfig from '../api/config'
import UserInfoDisplay from '../components/user-info/display/UserInfoDisplay'
import { CustomAlert } from '../styles/utils'

const fetchUserInfo = async (userId?: string): Promise<UserInfo | null> => {
  if (userId === undefined) {
    return null
  }
  const userInfoApi = new UserInfoApi(apiConfig)
  try {
    const response = await userInfoApi.getUserInfo(userId)
    return response.data
  } catch {
    return null
  }
}

interface UserIdContext {
  userId: string
}

function UserInfoViewScreen (): JSX.Element {
  const { userId } = useParams()
  const { userId: loggedInUserId } = useOutletContext<UserIdContext>()

  const { data: userInfo, status } = useQuery({
    queryKey: [userId],
    queryFn: async () => await fetchUserInfo(userId)
  })

  return (
    status === 'success' && userInfo !== null
      ? <UserInfoDisplay userInfo={userInfo}/>
      : status === 'loading'
        ? <CustomAlert severity='info'>
            정보를 가져오는 중
          </CustomAlert>
        : userId === loggedInUserId
          ? <CustomAlert severity='error'>
              정보 수정 탭에서 정보를 입력해 주세요.
            </CustomAlert>
          : <CustomAlert severity='error'>
              유저를 찾을 수 없습니다.
            </CustomAlert>
  )
}

export default UserInfoViewScreen
