import {
  Grid
} from '@mui/material'
import React from 'react'
import { type UserInfoUpdate } from '../../../api'
import AdditionalInfoEdit from './AdditionalInfoEdit'
import ContactListEdit from './ContactListEdit'
import EducationListEdit from './EducationListEdit'
import ExperienceListEdit from './ExperienceListEdit'
import ExternalLinkEdit from './ExternalLinkEdit'
import ProfileImageUpload from './ProfileImageUpload'
import SimpleUserInfoEdit from './SimpleUserInfoEdit'
import { type UserInfoUpdateAction } from './userInfoReducer'

function UserInfoEdit ({
  userInfoUpdated, userInfoUpdate, profileImageUrl, setProfileImageUrl, dispatch
}: {
  userInfoUpdated: boolean
  userInfoUpdate: UserInfoUpdate
  profileImageUrl: string | null
  setProfileImageUrl: React.Dispatch<string | null>
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ProfileImageUpload
          userInfoUpdated={userInfoUpdated}
          {...userInfoUpdate}
          profileImageUrl={profileImageUrl}
          setProfileImageUrl={setProfileImageUrl}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <SimpleUserInfoEdit {...userInfoUpdate} dispatch={dispatch}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ExperienceListEdit
          experienceUpdateList={
            userInfoUpdate.experienceUpdateList ?? []
          }
          dispatch={dispatch}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <EducationListEdit
          educationUpdateList={
            userInfoUpdate.educationUpdateList ?? []
          }
          dispatch={dispatch}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ContactListEdit
          contactUpdateList={
            userInfoUpdate.contactUpdateList ?? []
          }
          dispatch={dispatch}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <ExternalLinkEdit {...userInfoUpdate} dispatch={dispatch}/>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <AdditionalInfoEdit {...userInfoUpdate} dispatch={dispatch}/>
      </Grid>
    </Grid>
  )
}

export default UserInfoEdit
