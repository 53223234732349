import { AddCircle, RemoveCircle } from '@mui/icons-material'
import {
  Container,
  Grid,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { type EducationUpdate } from '../../../api'
import EducationAppendDialog from './EducationAppendDialog'
import ListRemoveDialog from './ListRemoveDialog'
import { type UserInfoUpdateAction } from './userInfoReducer'

function EducationListEdit ({
  educationUpdateList,
  dispatch
}: {
  educationUpdateList: EducationUpdate[]
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [removeIdx, setRemoveIdx] = useState<number | null>(null)

  const handleRemove = (): void => {
    if (removeIdx !== null) {
      dispatch({
        type: 'list-remove',
        key: 'educationUpdateList',
        data: educationUpdateList[removeIdx],
        idx: removeIdx
      })
      setRemoveIdx(null)
    }
    setRemoveDialogOpen(false)
  }

  const cancelRemove = (): void => {
    setRemoveIdx(null)
    setRemoveDialogOpen(false)
  }

  const handleAppend = (item: EducationUpdate): void => {
    dispatch({
      type: 'list-append',
      key: 'educationUpdateList',
      data: item
    })
    setAddDialogOpen(false)
  }

  const cancelAppend = (): void => {
    setAddDialogOpen(false)
  }

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5 }}>
      <ListRemoveDialog
        open={removeDialogOpen}
        onConfirm={handleRemove}
        onCancel={cancelRemove}
      />
      <EducationAppendDialog
        open={addDialogOpen}
        onConfirm={handleAppend}
        onCancel={cancelAppend}
      />
      {/* educationList */}
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            학력
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>기간</TableCell>
                  <TableCell align="center">내용</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {educationUpdateList.map((educationUpdate, i) => (
                  <TableRow key={i}>
                    <TableCell>{
                        educationUpdate.startYear ?? ''
                      } - {
                        educationUpdate.endYear ?? ''
                    }</TableCell>
                    <TableCell align="center">
                      {educationUpdate.content}
                    </TableCell>
                    <TableCell align="center">
                      <RemoveCircle
                        sx={{ color: '#FFA500' }}
                        onClick={() => {
                          setRemoveIdx(i)
                          setRemoveDialogOpen(true)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12}>
            <Container sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
              <AddCircle sx={{ color: '#FFA500' }} onClick={() => {
                setAddDialogOpen(true)
              }}/>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EducationListEdit
