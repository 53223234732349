import {
  Box
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import React from 'react'
import {
  type PlainTextUserInfoUpdateAction,
  type UserInfoUpdateAction
} from './userInfoReducer'

function DateUserInfoEdit ({
  keyName, name, value, dispatch
}: {
  keyName: PlainTextUserInfoUpdateAction['key']
  name: string
  value: string | undefined
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  return (
    <Box sx={{ p: 0.5, m: 0.5 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
        <DatePicker
          sx={{ display: 'flex' }}
          label={name}
          value={value !== undefined ? dayjs(value) : undefined}
          slotProps={{
            actionBar: {
              actions: ['clear', 'accept']
            },
            textField: {
              required: false
            }
          }}
          onChange={
            (value) => {
              dispatch({
                type: 'plaintext',
                key: keyName,
                data: value !== null
                  ? value.format('YYYY-MM-DD')
                  : undefined
              })
            }
          }
        />
      </LocalizationProvider>
    </Box>
  )
}

export default DateUserInfoEdit
