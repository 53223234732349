import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { useReducer } from 'react'
import { ContactType, type ContactUpdate } from '../../../api'
import SelectUserInfoEdit from './SelectUserInfoEdit'
import TextUserInfoEdit from './TextUserInfoEdit'

export interface ContactUpdateAction {
  type: 'plaintext'
  key: 'contactType' | 'content'
  data: string
}

function contactUpdateReducer (
  contactUpdate: ContactUpdate,
  action: ContactUpdateAction
): ContactUpdate {
  return {
    ...contactUpdate,
    [action.key]: action.data
  }
}

function ContactAppendDialog ({
  open, onConfirm, onCancel
}: {
  open: boolean
  onConfirm: (item: ContactUpdate) => void
  onCancel: () => void
}): JSX.Element {
  const [contactUpdate, dispatch] = useReducer(
    contactUpdateReducer,
    {
      contactType: ContactType.Phone,
      content: ''
    }
  )

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>연락처 추가</DialogTitle>
      <DialogContent>
        <SelectUserInfoEdit
          keyName='contactType'
          name='유형'
          value={contactUpdate.contactType}
          options={[ContactType.Email, ContactType.Other, ContactType.Phone]}
          defaultValue={ContactType.Email}
          required={true}
          dispatch={dispatch}
        />
        <TextUserInfoEdit
          keyName='content'
          name='연락처'
          value={contactUpdate.content}
          required={true}
          dispatch={dispatch}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          sx={{ color: '#FFA500' }}
        >
          취소
        </Button>
        <Button
          onClick={() => { onConfirm(contactUpdate) }}
          sx={{ color: '#FFA500' }}
          disabled={
            contactUpdate.content === ''
          }
          autoFocus
        >
          추가
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactAppendDialog
