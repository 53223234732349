import {
  Grid,
  Paper,
  Typography
} from '@mui/material'
import React from 'react'
import TextUserInfoEdit from './TextUserInfoEdit'
import {
  type UserInfoUpdateAction
} from './userInfoReducer'

function ExternalLinkEdit ({
  googleScholarUrl,
  linkedinUrl,
  homepageUrl,
  dispatch
}: {
  googleScholarUrl?: string
  linkedinUrl?: string
  homepageUrl?: string
  dispatch: React.Dispatch<UserInfoUpdateAction>
}): JSX.Element {
  const validateUrl = (url: string): boolean =>
    url === '' || url.startsWith('http://') || url.startsWith('https://')

  return (
    <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5 }}>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <Typography variant='h6' p={0.5} m={0.5}>
            링크
          </Typography>
        </Grid>
        {/* googleScholarUrl */}
        <Grid item xs={12}>
          <TextUserInfoEdit
            keyName='googleScholarUrl'
            name='Google Scholar'
            value={googleScholarUrl ?? ''}
            dispatch={dispatch}
            required={false}
            helperText={'"http://" 또는 "https://"로 시작하는 주소를 입력하세요.'}
            valid={validateUrl(googleScholarUrl ?? '')}
          />
        </Grid>
        {/* linkedinUrl */}
        <Grid item xs={12}>
          <TextUserInfoEdit
            keyName='linkedinUrl'
            name='LinkedIn'
            value={linkedinUrl ?? ''}
            dispatch={dispatch}
            required={false}
            helperText={'"http://" 또는 "https://"로 시작하는 주소를 입력하세요.'}
            valid={validateUrl(linkedinUrl ?? '')}
          />
        </Grid>
        {/* homepageUrl */}
        <Grid item xs={12}>
          <TextUserInfoEdit
            keyName='homepageUrl'
            name='Homepage'
            value={homepageUrl ?? ''}
            dispatch={dispatch}
            required={false}
            helperText={'"http://" 또는 "https://"로 시작하는 주소를 입력하세요.'}
            valid={validateUrl(homepageUrl ?? '')}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ExternalLinkEdit
