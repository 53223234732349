import { AxiosError, HttpStatusCode } from 'axios'
import { FirebaseError } from 'firebase/app'
import {
  signInWithEmailAndPassword,
  type User as FirebaseUser
} from 'firebase/auth'
import { UserApi, type User } from '../api'
import apiConfig from '../api/config'
import { auth } from './firebase'

export const login = async (
  email: string,
  password: string
): Promise<void> => {
  if (!validateEmail(email)) {
    throw new AuthError('이메일 형식이 올바르지 않습니다.')
  }

  if (password.length < 6) {
    throw new AuthError('비밀번호는 6글자 이상이어야 합니다.')
  }

  try {
    const cred = await signInWithEmailAndPassword(auth, email, password)
    const userId = cred.user.uid

    const userApi = new UserApi(apiConfig)
    const user = await userApi.getUser(userId)

    if (user === null) {
      throw new AuthError('존재하지 않는 사용자입니다.')
    }
  } catch (err: any) {
    if (err instanceof FirebaseError) {
      switch (err.code) {
        case 'auth/user-not-found':
          throw new AuthError('존재하지 않는 사용자입니다.')
        case 'auth/wrong-password':
          throw new AuthError('비밀번호가 올바르지 않습니다.')
        case 'auth/email-already-in-use':
          throw new AuthError('이미 사용 중인 이메일입니다.')
        case 'auth/weak-password':
          throw new AuthError('비밀번호는 6글자 이상이어야 합니다.')
        case 'auth/network-request-failed':
          throw new AuthError('네트워크 연결에 실패 하였습니다.')
        case 'auth/invalid-email':
          throw new AuthError('잘못된 이메일 형식입니다.')
        case 'auth/internal-error':
          throw new AuthError('잘못된 요청입니다.')
        case 'auth/too-many-requests':
          throw new AuthError('비밀번호를 너무 많이 틀렸습니다. 잠시 후 다시 시도해주세요.')
      }
    }
    throw new AuthError('로그인에 실패하였습니다.')
  }
}

export const logout = async (): Promise<void> => {
  await auth.signOut()
}

export function validateEmail (email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function getCurrentFirebaseUser (): FirebaseUser | null {
  return auth.currentUser
}

export async function getCurrentUser (): Promise<User | null> {
  const firebaseUser = getCurrentFirebaseUser()
  if (firebaseUser === null) {
    return null
  }

  const userId = firebaseUser.uid
  const userApi = new UserApi(apiConfig)
  try {
    const response = await userApi.getUser(userId)
    return response.data
  } catch (err) {
    return null
  }
}

export async function registerWithToken (
  email: string,
  password: string,
  token: string
): Promise<User> {
  try {
    const userApi = new UserApi(apiConfig)
    const response = await userApi.createUserWithToken(token, {
      email,
      password
    })
    return response.data
  } catch (err: any) {
    if (err instanceof AxiosError) {
      if (err.response?.status === HttpStatusCode.Forbidden) {
        throw new AuthError('권한이 없습니다.')
      }
      const message: string = err.response?.data?.detail
      if (message !== undefined) {
        throw new AuthError(message)
      }
    }
    throw new AuthError('회원가입에 실패하였습니다.')
  }
}

export class AuthError extends Error {
  message: string

  constructor (message: string) {
    super()
    this.message = message
  }
}
