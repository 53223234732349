import {
  Box,
  Grid,
  Paper
} from '@mui/material'
import { Link } from 'react-router-dom'
import { type UserInfo } from '../../../api'
import ProfileImage from './ProfileImage'
import UserInfoAttr from './UserInfoAttr'

function UserInfoPreview ({ userInfo }: {
  userInfo: UserInfo
}): JSX.Element {
  const {
    userId,
    profileImageUrl,
    generation,
    name,
    englishName,
    major,
    keywordList
  } = userInfo

  return (
    <Box
      component={Link}
      to={`/profile/${userId}`}
      style={{ textDecoration: 'none' }}
    >
      <Paper sx={{ flexGrow: 1, p: 0.5, m: 0.5, backgroundColor: '#FAFAFA' }}>
        <Grid container direction='row' columns={12} spacing={2}>
          <Grid item xs={3}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Box>
              <ProfileImage
                profileImageUrl={profileImageUrl}
                sx={{ width: 75, height: 75, marginLeft: 2 }}
              />
            </Box>
          </Grid>
          <Grid item xs={9} container columns={12}>
            <Grid item xs={3}>
              <UserInfoAttr name='기수' value={generation}/>
            </Grid>
            <Grid item xs={9}>
              <UserInfoAttr name='이름' value={
                `${name} (${englishName})`
              }/>
            </Grid>
            <Grid item xs={12}>
              <UserInfoAttr name='전공' value={major}/>
            </Grid>
            <Grid item xs={12}>
              <UserInfoAttr name='키워드' value={
                keywordList !== undefined && keywordList.length > 0
                  ? keywordList
                    .map(keyword => keyword.content)
                    .reduce((prev, curr) => `${prev}, ${curr}`)
                  : ' '
              }/>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default UserInfoPreview
