import { Home } from '@mui/icons-material'
import {
  Divider,
  IconButton,
  Stack
} from '@mui/material'
import { GoogleScholarIcon, LinkedInIcon } from '../Icons'

function ExternalLinksDisplay ({
  googleScholarUrl,
  linkedinUrl,
  homepageUrl
}: {
  googleScholarUrl?: string
  linkedinUrl?: string
  homepageUrl?: string
}): JSX.Element {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      {/* googleScholarUrl */}
      { googleScholarUrl !== undefined &&
        googleScholarUrl !== null &&
        <IconButton
          href={googleScholarUrl}
          target="_blank"
          rel="noopener"
        >
          <GoogleScholarIcon/>
        </IconButton>
      }
      {/* linkedinUrl */}
      { linkedinUrl !== undefined &&
        linkedinUrl !== null &&
        <IconButton
          href={linkedinUrl}
          target="_blank"
          rel="noopener"
        >
          <LinkedInIcon/>
        </IconButton>
      }
      {/* hompageUrl */}
      { homepageUrl !== undefined &&
        homepageUrl !== null &&
        <IconButton
          href={homepageUrl}
          target="_blank"
          rel="noopener"
        >
          <Home/>
        </IconButton>
      }
    </Stack>
  )
}

export default ExternalLinksDisplay
