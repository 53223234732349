import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material'
import { CustomAlert } from '../../../styles/utils'

function ProfileImagePreventDialog ({
  open, onClose
}: {
  open: boolean
  onClose: () => void
}): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <CustomAlert severity='warning'>
          프로필 사진을 업로드하기 전에 다른 정보를 먼저 입력하고 저장해 주세요.
        </CustomAlert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{ color: '#FFA500' }}
          autoFocus
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfileImagePreventDialog
