// StyledComponents.tsx
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'

export const ChangePasswordTextField = styled(TextField)({
  margin: 'normal',
  variant: 'outlined',
  marginBottom: '12px',
  width: '100%'
})

export const ChangePasswordButton = styled(Button)({
  // Add your common Button styles here
  variant: 'contained',
  backgroundColor: '#FFA500',
  color: '#ffffff',
  marginTop: '4px',
  marginBottom: '18px',
  height: '50px',
  width: '100%',
  '&:hover': {
    backgroundColor: '#808080'
  }
})

export const ChangePasswordAlert = styled(Alert)({
  // Add your common Alert (error) styles here
  marginBottom: '18px',
  variant: 'h5'
})

// Add other common styled components as needed
