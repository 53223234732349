import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { CustomAlert } from '../../../styles/utils'
import ProfileImage from '../display/ProfileImage'

function ProfileImagePreviewDialog ({
  open, imageFile, onConfirm, onCancel
}: {
  open: boolean
  imageFile: File | null
  onConfirm: () => void
  onCancel: () => void
}): JSX.Element {
  const previewURL = imageFile !== null
    ? URL.createObjectURL(imageFile)
    : null

  const maxFileSize = 5 * 1024 * 1024
  let dialogContent: JSX.Element
  let error: boolean
  if (imageFile !== null && imageFile.size > maxFileSize) {
    error = true
    dialogContent = (
      <CustomAlert severity='error'>
        파일의 크기는 {maxFileSize / 1048576}MB를 넘을 수 없습니다.
      </CustomAlert>
    )
  } else if (imageFile !== null && !imageFile.type.startsWith('image/')) {
    error = true
    dialogContent = (
      <CustomAlert severity='error'>
        이미지 파일을 업로드해 주세요.
      </CustomAlert>
    )
  } else {
    error = false
    dialogContent = (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        margin={2}
      >
        <ProfileImage
          profileImageUrl={previewURL}
          sx={{ width: 250, height: 250 }}
        />
      </Box>
    )
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>프로필 사진 변경</DialogTitle>
      <DialogContent>
        { dialogContent }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          sx={{ color: '#FFA500' }}
        >
          취소
        </Button>
        <Button
          onClick={onConfirm}
          sx={{ color: '#FFA500' }}
          disabled={error}
          autoFocus
        >
          업로드
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProfileImagePreviewDialog
