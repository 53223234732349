// CustomAppbar.tsx
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { logout } from '../auth/auth'

interface UserIdContext {
  userId: string
}

function CustomMenu ({ onClose, anchorEl }: {
  onClose: () => void
  anchorEl: HTMLElement | null
}): JSX.Element {
  const navigate = useNavigate()
  const { userId } = useOutletContext<UserIdContext>()

  const handleLogout = (): void => {
    logout().then(() => {
      navigate('/auth/login')
    }).catch(() => {
    })
  }

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={anchorEl !== null}
      onClose={onClose}
    >
      <MenuItem component={Link} to={`/profile/${userId}`}>
        내 정보
      </MenuItem>
      <MenuItem component={Link} to={`/profile/${userId}/edit`}>
        정보 수정
      </MenuItem>
      <MenuItem component={Link} to='/change-password'>
        비밀번호 변경
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        로그아웃
      </MenuItem>
    </Menu>
  )
}

export default CustomMenu
