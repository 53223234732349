import { Box, List, ListItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { type User } from '../api'
import { AuthError, getCurrentUser, login } from '../auth/auth'
import logo from '../figures/logo_kr.png'
import {
  LoginButton,
  LoginTextField
} from '../styles/LoginScreen'
import '../styles/LoginScreen.css'
import {
  CustomAlert
} from '../styles/utils'

async function handleLogin (
  email: string,
  password: string
): Promise<User | null> {
  await login(email, password)
  return await getCurrentUser()
}

function LoginScreen (): JSX.Element {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const { data, status, error, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => await handleLogin(email, password),
    cacheTime: 0,
    enabled: false,
    retry: 1
  })

  useEffect(() => {
    const user = data
    if (user !== null && user !== undefined) {
      navigate('/home')
    }
  }, [data, navigate])

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      void refetch()
    }
  }

  return (
    <Box className="login-screen-container">
      <img src={logo} alt="logo" className="logo" />
      <LoginTextField
        value={email}
        onChange={(evt) => { setEmail(evt.target.value) }}
        label="Email"
        className="input-field"
      />
      <LoginTextField
        value={password}
        onChange={(evt) => { setPassword(evt.target.value) }}
        onKeyPress = {handleKeyPress}
        label="Password"
        type="password"
        className="password-field"
      />
      <LoginButton
        onClick={() => { void refetch() }}
        className="login-button"
      >
        Login
      </LoginButton>
      { status === 'error' &&
        <CustomAlert severity="error" className="login-failed">
          { error instanceof AuthError ? error.message : 'Unknown error' }
        </CustomAlert>
      }
      { status === 'loading' &&
        <CustomAlert severity="info" className="login-pending">
          로그인을 시도하는 중
        </CustomAlert>
      }
      <List>
        <ListItem
          disablePadding
          sx=
          {{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem', alignItems: 'center' }}
          >
          문의사항 stem.eng.snu@gmail.com
          </Typography>
        </ListItem>
      </List>
    </Box>
  )
}

export default LoginScreen
