import { Box, Container } from '@mui/material'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { SearchApi, SearchTag, type UserInfo } from '../api'
import apiConfig from '../api/config'
import SearchBox from '../components/SearchBox'
import SearchResult from '../components/SearchResult'

const fetchSearchResult = async (
  searchTag: SearchTag,
  query: string
): Promise<UserInfo[]> => {
  const searchApi = new SearchApi(apiConfig)
  const response = await searchApi.getProfileByQueryAndTag(searchTag, query)
  return response.data
}

const stringToSearchTag = (str: string | null): SearchTag => {
  if (str === null) return SearchTag.General
  if (str === SearchTag.Education) return SearchTag.Education
  else if (str === SearchTag.EnglishName) return SearchTag.EnglishName
  else if (str === SearchTag.Experience) return SearchTag.Experience
  else if (str === SearchTag.Generation) return SearchTag.Generation
  else if (str === SearchTag.Keyword) return SearchTag.Keyword
  else if (str === SearchTag.Major) return SearchTag.Major
  else if (str === SearchTag.Name) return SearchTag.Name
  else if (str === SearchTag.Residence) return SearchTag.Residence
  else return SearchTag.General
}

function SearchScreen (): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchTag = stringToSearchTag(searchParams.get('searchTag'))
  const query = searchParams.get('query') ?? ''

  const { data, status } = useQuery([
    'search-user-info', searchTag, query
  ], async (): Promise<UserInfo[]> => await fetchSearchResult(searchTag, query))

  const userInfoList = data ?? []

  const handleSearch = (query: string): void => {
    setSearchParams({
      searchTag,
      query
    })
  }

  return (
    <Box
      justifyContent='center'
      alignItems='center'
      height='100%'
      width='100%'
    >
      <Container>
        <SearchBox initialQuery={query} onSearch={handleSearch} focusOnRender/>
      </Container>
      <br/>
      {
        status === 'success' &&
        <SearchResult userInfoList={userInfoList}/>
      }
    </Box>
  )
}

export default SearchScreen
